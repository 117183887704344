<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Confirm Email</h2>
    </v-responsive>

    <v-progress-circular
      v-if="loadingToken"
      indeterminate
      color="secondary"
      class="pa-4 mx-auto d-block"
    ></v-progress-circular>

    <v-snackbar
      v-model="snackbarTokenVisible"
      :timeout="-1"
      color="red lighten-1"
    >
      {{ snackbarTokenText }}
    </v-snackbar>

  </v-container>
</template>
<script>

import authService from '@/services/auth.service';

export default {
  name: 'LoginView',

  data() {
    return {
      token: this?.$route?.query?.token,

      tokenError: null,
      loadingToken: false,
    };
  },

  async mounted() {
    this.loadingToken = true;
    if (this.token) {
      try {
        await authService.confirmEmail(this.token);
        this.$router.push({ path: '/login', query: { confirmEmail: true } });
      } catch (e) {
        this.snackbarTokenText = e?.response?.data?.payload?.message || 'Failed! Email confirmation was not successful!';
        this.snackbarTokenVisible = true;
      }
    } else {
      this.snackbarTokenText = 'Confirmation email session is not valid.';
      this.snackbarTokenVisible = true;
    }
    this.loadingToken = false;
  },

};
</script>
