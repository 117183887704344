<template>
  <v-app>
    <v-app-bar
      v-if="$route.name !== 'Projects' && $route.name !== 'Settings'"
      flat class="vappbar_fix_height" color="transparent"
    >
      <v-container class="py-0 px-0 px-sm-2 fill-height">
        <router-link
          to="/"
          class="d-flex align-center text-decoration-none mr-2 toolbar3-min-width"
        >
          <img src="/assets/logo.png" alt="" height="48" class="mr-1">
        </router-link>

        <v-spacer></v-spacer>

        <div class="d-none d-md-block">
          <v-btn
            v-for="(item, index) in menu"
            :key="index"
            :to="item.link"
            text
            class="mx-1 font-weight-medium"
          >
            {{ item.title }}
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <div class="toolbar3-min-width text-right">
          <v-btn color="secondary" large class="mx-1" outlined to="/steps">
            Get Started
          </v-btn>
           <v-btn color="primary" large class="mx-1" to="/register">
            Sign Up
          </v-btn>
          <v-btn color="primary" large class="mx-1" outlined to="/login">
            Log In
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
    <router-view/>
  </v-app>
</template>

<script>
import config from './configs';

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href })),
    ],
  },
};
</script>
<style scoped>
  .vappbar_fix_height {
    height: 80px;
    max-height: 80px;
  }
</style>
