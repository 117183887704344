<template>
  <v-timeline class="ma-10">
    <v-timeline-item
      v-for="n in 3"
      :key="n"
      large
      class="dot-white"
    >
      <template v-slot:icon>
        <v-avatar class="avatar-text" size="35" rounded="0">
          <v-img alt="" :src="`/assets/steps/step${n}.png`" />
        </v-avatar>
      </template>
      <template v-slot:opposite>
        <span>{{text[n].opposite}}</span>
      </template>
      <v-card class="elevation-2">
        <v-card-title class="headline">{{text[n].title}}</v-card-title>
        <v-card-text v-html="text[n].content"></v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>

export default {
  name: 'StepsView',

  data() {
    return {
      text: [{}, {
        opposite: 'Make sure you meet the requirements',
        title: '1. Basic Requirements',
        content: 'For the setup, you need to make sure that you are using the latest version for one of our recommended browsers: <b>Mozilla Firefox</b>, <b>Google Chrome</b>, <b>Microsoft Edge</b> or <b>Safari</b>.',
      }, {
        opposite: 'Create your account',
        title: '2. Sign Up',
        content: 'Next, head up to the Sign Up page. There, you should register with an account using your email. You’ll be requested to fill in a strong password, job domain, and company. You will also be required to complete the reCAPTCHA challenge and agree with our terms and conditions.',
      }, {
        opposite: 'Confirm your account and log in',
        title: '3. Confirm Email',
        content: 'You should receive an email where you are asked to confirm your account. You can now login on the platform with the account you created. After logging in on our platform, you should be able to create a new project.<br />Now, enjoy! Happy developing with OxidOS!',
      }],
    };
  },
};
</script>
<style>
.v-application .primary.v-timeline-item__inner-dot { background-color: white !important; }
</style>
