import { AxiosResponse } from 'axios';
import { IProject } from '@common/project';
import { IOxidOSClusterStatus } from '@common/cluster';
import { api } from './api.service';

export interface IProjectAndStatus {
  project: IProject,
  status: IOxidOSClusterStatus,
}

async function getAllProjects() {
  const response = await api().get('/projects');
  return response;
}

async function getProject(projectId: string): Promise<AxiosResponse<IProjectAndStatus>> {
  const response = await api().get<IProjectAndStatus>(`/projects/${projectId}`);
  return response;
}

async function addProject(projectName: string) {
  const response = await api().post('/projects', { projectName });
  return response;
}

async function wakeProject(projectId: string) {
  const response = await api().patch(`/projects/${projectId}`);
  return response;
}

async function changeProject(projectId: string, projectName: string) {
  const response = await api().put(`/projects/${projectId}`, { projectName });
  return response;
}

async function deleteProject(projectId: string) {
  const response = await api().delete(`/projects/${projectId}`);
  return response;
}

export default {
  getAllProjects,
  getProject,
  addProject,
  wakeProject,
  changeProject,
  deleteProject,
};
