<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer class="elevation-1" :right="$vuetify.rtl" width="260" permanent>
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img src="/assets/logo.png" width="100%" alt="logo" class="mr-1">
        </div>
        <v-divider class="mb-1"></v-divider>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <main-menu :menu="logoutMenu" @click="logout" />
      </template>
    </v-navigation-drawer>

    <v-container :fluid="false" class="pt-3">
      <div class="py-2 fill-height" style="position:relative">
        <h1 class="text-h4">Dashboard</h1>
        <v-divider class="my-2"></v-divider>
        <v-card>
          <div class="d-flex flex-row align-center px-2">
            <div class="d-flex flex-row align-center text-h6">
              <div class="text-center">{{`Projects`}}</div>
            </div>
            <v-spacer />
            <v-btn class="primary my-2" @click="dialog = true"
            :disabled="projects.length === maxProjects">Create New
              Project</v-btn>
          </div>
          <v-data-table v-model="formProjects" :headers="headers"
          :items="formProjects" class="flex-grow-1"
            :loading="loading" :hide-default-footer="true">
            <template v-slot:item.name="{ item }">
              <div class="font-weight-bold">{{ item.name }}</div>
            </template>

            <template v-slot:item.status="{ item }">
              <template v-if="item.inWork">
                <v-progress-circular indeterminate color="secondary" :size="24"
                class="mr-2"></v-progress-circular>
                <span v-if="item.deleted">Deleting: </span>
                <span v-else>Creating </span>
                <span class="font-weight-bold">{{projectStatus?.[item._id]}}</span>
                <span>{{' (this may take up to 5 minutes)'}}</span>
              </template>
              <template v-else-if="item.putToSleep">
                  <v-icon color="secondary">
                    mdi-sleep
                  </v-icon>
              </template>
              <template v-else>
                  <v-icon color="success">
                    mdi-check-circle
                  </v-icon>
              </template>
            </template>

            <template v-slot:item.date="{ item }">
              <div v-if="!item.inWork && !item.deleted" target="_blank"
              class="font-weight-bold">
                {{ getExpirationDate(item) }}
              </div>
            </template>

            <template v-slot:item.url="{ item }">
              <a v-if="!item.inWork && !item.deleted && !item.putToSleep" target="_blank"
                :href="getClusterURL(item._id, item.vscodeToken).actual">
                {{getClusterURL(item._id, item.vscodeToken).visible}}
              </a>
              <div v-else-if="item.putToSleep">
                Project is idle
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="actions">
                <v-btn icon @click="wakeItem(item)" :disabled="item.deleted || !item.putToSleep">
                  <v-icon>mdi-play</v-icon>
                </v-btn>
                <v-btn icon @click="copyURL(item)" :disabled="item.deleted || item.inWork">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon @click="editItem(item)" :disabled="item.deleted">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(item)" :disabled="item.deleted">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <div v-if="projects.length === maxProjects" class="d-flex flex-row justify-center mt-4">
          <div class="text-overline secondary--text mb-3">
            You have reached the maximum number of projects you can create
          </div>
        </div>
        <div v-if="projects.length >= 1" class="d-flex flex-row justify-center mt-2">
          <div class="text-overline secondary--text mb-3">
            Note: Projects that have not been accessed for 14 days will be automatically deleted
          </div>
        </div>
        <div class="
            d-flex flex-column flex-sm-row align-center justify-space-between
            overline position-absolute
          " style="bottom:0;left:0;right:0">
          <div class="d-flex flex-column flex-sm-row align-center">
            <div class="secondary--text">© OxidOS 2024</div>
            <a href="assets/Privacy_Notice_OxidOS_M23.pdf"
              class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">
              Privacy Policy</a>
            <a href="assets/End_User_License_Agreement_OxidOS_Demonstrator_M23.pdf"
              class="text-decoration-none ml-sm-2 secondary--text text--lighten-3">
              Terms of Service</a>
          </div>
          <div class="mt-2 mt-md-0">
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank"
              href="https://www.facebook.com/oxidos.automotive/">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44
                9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19
                2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10
                10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z" />
              </svg>
            </v-btn>
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank" href="https://twitter.com/OxidOSauto">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425
                5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86
                13.028h1.36L4.323 2.145H2.865z" />
              </svg>
            </v-btn>
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank"
              href="https://www.linkedin.com/company/oxidos-automotive/">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>
        <v-card-text>
          Please make sure you are using <b>Mozilla Firefox</b>, <b>Google Chrome</b>,
           <b>Microsoft Edge</b> or <b>Safari</b>.
        </v-card-text>
        <v-card-text>
          <v-text-field v-model="editedItem.name" label="Project name"
          @keyup.enter="save"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            Cancel
          </v-btn>
          <v-btn class="primary" @click="save" :loading="loadingButton">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          Are you sure you want to delete this project?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">
            Cancel
          </v-btn>
          <v-btn class="error" @click="deleteItemConfirm" :loading="loadingButton">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbarErrorVisible" :timeout="3000" color="red lighten-1">
      {{ snackbarErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn variant="text" text v-bind="attrs" @click="snackbarErrorVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarVisible" :timeout="3000" color="secondary lighten-1">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn variant="text" text v-bind="attrs" @click="snackbarVisible = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-layout>

</template>

<script>
import _ from 'lodash';
import { ClusterGeneralStatus, getClusterURL } from '@common/cluster';
import projectsService from '@/services/projects.service';
import authService from '@/services/auth.service';
import { documentationURL } from '@/services/api.service';
import MainMenu from '@/components/navigation/MainMenu.vue';

export default {
  components: {
    MainMenu,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
        width: '20%',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        width: '20%',
      },
      {
        text: 'Deletion date',
        align: 'center',
        sortable: false,
        value: 'date',
        width: '10%',
      },
      {
        text: 'URL', value: 'url', align: 'start', sortable: false, width: '30%',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', sortable: false, width: '20%',
      },
    ],
    projects: [],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    loading: false,
    loadingButton: false,

    snackbarVisible: false,
    snackbarText: '',

    snackbarErrorVisible: false,
    snackbarErrorText: '',

    projectStatus: {},
    projectTimeout: {},

    menu: [{
      text: '',
      items: [
        {
          icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/',
        },
        {
          icon: 'mdi-school', key: 'menu.tutorial', text: 'Tutorials', link: `${documentationURL}/documentation/tutorial/index.html`, external: true,
        },
        {
          icon: 'mdi-tools', key: 'menu.workshop', text: 'Workshop', link: `${documentationURL}/documentation/workshop/index.html`, external: true,
        },
        {
          icon: 'mdi-book', key: 'menu.manual', text: 'Manual', link: `${documentationURL}/documentation/manual/index.html`, external: true,
        },
        {
          icon: 'mdi-book-cog', key: 'menu.api.documentation', text: 'API Documentation', link: `${documentationURL}/documentation/api/index.html`, external: true,
        },
        {
          icon: 'mdi-lifebuoy',
          key: 'menu.support',
          text: 'Support',
          link: 'https://github.com/orgs/OxidosAutomotive/discussions/categories/developer-platform-issues-and-feedback',
          external: true,
        },
        {
          icon: 'mdi-cog', key: 'menu.settings', text: 'Settings', link: '/settings',
        },
      ],
    }],
    logoutMenu: [{ text: '', items: [{ icon: 'mdi-logout', key: 'menu.logout', text: 'Logout' }] }],

    maxProjects: null,

  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New project' : 'Edit project name';
    },
    formProjects() {
      return _.map(this.projects, (p) => ({
        ...p,
        status: this.projectStatus[p._id],
      }));
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },   
  },

  created() {
    this.initialize();
  },

  methods: {
    logout() {
      authService.logout();
      this.$router.push('/login');
    },
    async initialize() {
      this.loading = true;
      try {
        const allProjectsData = (await projectsService.getAllProjects()).data?.payload;
        this.projects = allProjectsData.projects;
        this.maxProjects = allProjectsData.maxProjects;
        for (let index = 0; index < this.projects.length; index += 1) {
          const element = this.projects[index];
          if (element.inWork) {
            if (element.deleted) {
              const stored = localStorage.getItem(element._id);
              if (stored) {
                this.projectStatus[element._id] = this.projectStatus[element._id] ?? stored;
              } else {
                localStorage.setItem(element._id, 'ingress');
                this.projectStatus[element._id] = this.projectStatus[element._id] ?? 'ingress';
              }
            } else {
              const stored = localStorage.getItem(element._id);
              if (stored) {
                this.projectStatus[element._id] = this.projectStatus[element._id] ?? stored;
              } else {
                localStorage.setItem(element._id, 'namespace');
                this.projectStatus[element._id] = this.projectStatus[element._id] ?? 'namespace';
              }
            }
            if (!this.projectTimeout[element._id]) {
              this.projectTimeout[element._id] = setTimeout(
                () => this.getProject(element._id),
                1000,
              );
            }
          }
        }
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed to fetch the projects!';
        this.snackbarErrorVisible = true;
      }
      this.loading = false;
    },

    async getProject(_id) {
      try {
        const { project, status } = (
          await projectsService.getProject(_id)
        ).data?.payload ?? { project: {}, status: {} };

        let clearEverything = false;

        if (status !== null && project.inWork) {
          if (project.deleted) {
            if (this.projectStatus[project._id] === '') {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'ingress',
              };
              localStorage.setItem(project._id, 'ingress');
            } else if (this.projectStatus[project._id] === 'ingress') {
              if (status.ingress.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'service',
                };
                localStorage.setItem(project._id, 'service');
              }
            } else if (this.projectStatus[project._id] === 'service') {
              if (status.service.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'pod',
                };
                localStorage.setItem(project._id, 'pod');
              }
            } else if (this.projectStatus[project._id] === 'pod') {
              if (status.pod.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'deployment',
                };
                localStorage.setItem(project._id, 'deployment');
              }
            } else if (this.projectStatus[project._id] === 'deployment') {
              if (status.deployment.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'cmap',
                };
                localStorage.setItem(project._id, 'cmap');
              }
            } else if (this.projectStatus[project._id] === 'cmap') {
              if (status.cmap.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'pvc',
                };
                localStorage.setItem(project._id, 'pvc');
              }
            } else if (this.projectStatus[project._id] === 'pvc') {
              if (status.pvc.value.status === ClusterGeneralStatus.UNKNOWN) {
                this.projectStatus = {
                  ...this.projectStatus,
                  [project._id]: 'namespace',
                };
                localStorage.setItem(project._id, 'namespace');
              }
            } else if (this.projectStatus[project._id] === 'namespace') {
              if (status.namespace.value.status === ClusterGeneralStatus.UNKNOWN) {
                clearEverything = true;
              }
            } else {
              clearEverything = true;
            }
          } /* deleted */ else if (this.projectStatus[project._id] === '') {
            this.projectStatus = {
              ...this.projectStatus,
              [project._id]: 'namespace',
            };
            localStorage.setItem(project._id, 'namespace');
          } else if (this.projectStatus[project._id] === 'namespace') {
            if (status.namespace.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'pvc',
              };
              localStorage.setItem(project._id, 'pvc');
            }
          } else if (this.projectStatus[project._id] === 'pvc') {
            if (status.pvc.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'cmap',
              };
              localStorage.setItem(project._id, 'cmap');
            }
          } else if (this.projectStatus[project._id] === 'cmap') {
            if (status.cmap.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'deployment',
              };
              localStorage.setItem(project._id, 'deployment');
            }
          } else if (this.projectStatus[project._id] === 'deployment') {
            if (status.deployment.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'pod',
              };
              localStorage.setItem(project._id, 'pod');
            }
          } else if (this.projectStatus[project._id] === 'pod') {
            if (status.pod.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'service',
              };
              localStorage.setItem(project._id, 'service');
            }
          } else if (this.projectStatus[project._id] === 'service') {
            if (status.service.value.status === ClusterGeneralStatus.CREATED) {
              this.projectStatus = {
                ...this.projectStatus,
                [project._id]: 'ingress',
              };
              localStorage.setItem(project._id, 'ingress');
            }
          } else if (this.projectStatus[project._id] === 'ingress') {
            if (status.ingress.value.status === ClusterGeneralStatus.CREATED) {
              clearEverything = true;
            }
          } else {
            clearEverything = true;
          }
        } else {
          clearEverything = true;
        }

        if (clearEverything) {
          delete this.projectStatus[project._id];
          this.projectStatus = {
            ...this.projectStatus,
          };
          localStorage.removeItem(project._id);
          clearTimeout(this.projectTimeout[project._id]);
          delete this.projectTimeout[project._id];
          await this.initialize();
        } else {
          this.projectTimeout[project._id] = setTimeout(() => this.getProject(project._id), 1000);
        }
      } catch (e) {
        console.error(e);
        console.error('Error taking project _id');
      }
    },

    async wakeItem(item) {
      await projectsService.wakeProject(item._id);
      this.snackbarText = `Tried to wake up project: ${item.name}`;
      this.snackbarVisible = true;
      await this.initialize();
    },

    editItem(item) {
      this.editedIndex = _.findIndex(this.projects, { _id: item._id });
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = _.findIndex(this.projects, { _id: item._id });
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loadingButton = true;
      const id = this.projects[this.editedIndex]?._id;
      if (!id) {
        this.snackbarErrorText = 'Cannot delete project.';
        this.snackbarErrorVisible = true;
        this.loadingButton = false;
        return;
      }
      try {
        await projectsService.deleteProject(id);
        this.snackbarText = 'Project in process of being deleted!';
        this.snackbarVisible = true;
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed to delete the project!';
        this.snackbarErrorVisible = true;
        this.loadingButton = false;
        return;
      }
      this.closeDelete();
      this.loadingButton = false;
      await this.initialize();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    async save() {
      this.loadingButton = true;

      if (this.editedIndex > -1) {
        const id = this.projects[this.editedIndex]?._id;
        if (!id) {
          this.snackbarErrorText = 'Cannot modify name.';
          this.snackbarErrorVisible = true;
          this.loadingButton = false;
          return;
        }
        const name = this.editedItem?.name;
        if (!name) {
          this.snackbarErrorText = 'Cannot leave name empty.';
          this.snackbarErrorVisible = true;
          this.loadingButton = false;
          return;
        }
        try {
          await projectsService.changeProject(id, name);
          this.snackbarText = 'Project name modified!';
          this.snackbarVisible = true;
        } catch (e) {
          this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed to modify the name of the project!';
          this.snackbarErrorVisible = true;
          this.loadingButton = false;
          return;
        }
      } else {
        const name = this.editedItem?.name;
        if (!name) {
          this.snackbarErrorText = 'Cannot leave name empty.';
          this.snackbarErrorVisible = true;
          this.loadingButton = false;
          return;
        }
        try {
          await projectsService.addProject(name);
          this.snackbarText = 'Project added!';
          this.snackbarVisible = true;
        } catch (e) {
          this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed to add a new project!';
          this.snackbarErrorVisible = true;
          this.loadingButton = false;
          return;
        }
      }

      this.close();
      this.loadingButton = false;
      await this.initialize();
    },
    getClusterURL,
    copyURL(item) {
      const text = getClusterURL(item._id, item.vscodeToken).actual;
      navigator.clipboard.writeText(text);
      this.snackbarText = 'URL copied to clipboard!';
      this.snackbarVisible = true;
    },
    getExpirationDate(item) {
      const date = new Date(item.expiration);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
