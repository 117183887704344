<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Create your OxidOS account</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <form ref="form" @submit.prevent>

          <v-text-field
            v-model="fullName"
            :error-messages="fullNameErrors"
            label="Full name"
            @blur="$v.fullName.$touch"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="Email"
            @blur="$v.email.$touch"
            outlined
          ></v-text-field>

          <div class="d-flex flex-column flex-md-row">
            <v-text-field
              v-model="password"
              :error-messages="passwordErrors"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @blur="$v.password.$touch"
              outlined
              class="mr-md-2 col-md"
            ></v-text-field>
              <v-text-field
              v-model="passwordConfirm"
              :error-messages="passwordConfirmErrors"
              label="Password confirmation"
              :type="showPasswordConfirm ? 'text' : 'password'"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              @blur="$v.passwordConfirm.$touch"
              outlined
              class="col-md"
            ></v-text-field>
          </div>

          <div class="d-flex flex-column flex-md-row">
            <v-text-field
              v-model="company"
              :error-messages="companyErrors"
              label="Company"
              @blur="$v.company.$touch"
              outlined
              class="mr-md-2 col-md"
            ></v-text-field>
            <v-select
              v-model="areaOfWork"
              :items="areaOfWorkItems"
              :error-messages="areaOfWorkErrors"
              label="Area of work"
              @change="$v.areaOfWork.$touch()"
              @blur="$v.areaOfWork.$touch()"
              outlined
              class="col-md"
            ></v-select>
          </div>

          <v-text-field
            v-model="linkedin"
            :error-messages="linkedinErrors"
            label="LinkedIn (optional)"
            @blur="$v.linkedin.$touch"
            outlined
          ></v-text-field>

          <v-row no-gutters class="pa-md-2 mt-auto">
            <v-col cols="1">
              <v-checkbox v-model="checkbox"
                  :error-messages="checkboxErrors"
                  :rules="[v => !!v || '']"
                  required>
                </v-checkbox>
            </v-col>
            <v-col>
              <v-sheet class="text-overline text-uppercase pa-md-2 mt-auto text-center">
                    You acknowledge that you have read and accepted the
                    <a href="assets/End_User_License_Agreement_OxidOS_Demonstrator_M23.pdf"
                    >EULA</a> license which includes: <b>non-commercial use</b>, <b>no changes</b>,
                    and <b>no distribution</b> of any products that are the property of
                    <b>OxidOS Automotive</b>. The products are for <b>evaluation purposes</b> only.
              </v-sheet>
            </v-col>
          </v-row>

          <v-row no-gutters class="pa-md-2">
            <v-col>
              <vue-recaptcha align="center" justify="center"
                @verify="onRecaptchaVerified"
                @expired="onRecaptchaExpired"
                sitekey="6LeKEIYpAAAAAPZPjn-NpIrYHhKOxjpjVcygh_0u"
              ></vue-recaptcha>
            </v-col>
          </v-row>

          <v-btn
            type="submit" class="secondary"
            value="register" :loading="loading" @click="register"
            block x-large
          >Create Account</v-btn>

        </form>
      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      By signing in, you agree to the
      <a href="assets/End_User_License_Agreement_OxidOS_Demonstrator_M23.pdf"
      >Terms of Service</a> & <a href="assets/Privacy_Notice_OxidOS_M23.pdf">Privacy Policy</a>
    </div>
    <v-snackbar
      v-model="registerErrorVisible"
      :timeout="3000"
      color="red lighten-1"
    >
      {{ registerError }}

      <template v-slot:action="{ attrs }">
        <v-btn
          variant="text"
          text
          v-bind="attrs"
          @click="registerErrorVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="recaptchaErrorVisible"
        :timeout="3000"
        color="red lighten-1"
      >
        {{ recaptchaError }}

        <template v-slot:action="{ attrs }">
          <v-btn
            variant="text"
            text
            v-bind="attrs"
            @click="recaptchaErrorVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

import { validationMixin } from 'vuelidate';
import {
  required, maxLength, email, sameAs,
} from 'vuelidate/lib/validators';
import authService from '@/services/auth.service';
import recaptchaService from '@/services/recaptcha.service';
import { DEFAULT_MAXIMUM_INPUT_STRING } from '@common/defaults';

export default {
  name: 'LoginView',

  mixins: [validationMixin],
  validations: {
    fullName: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    email: { required, email, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    password: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    passwordConfirm: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING), sameAsPassword: sameAs('password') },
    company: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    areaOfWork: { required },
    checkbox: { required },
    linkedin: { maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
  },

  data() {
    return {
      fullName: '',
      email: '',
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      company: '',
      areaOfWork: '',
      linkedin: '',
      checkbox: false,
      isRecaptchaVerified: false,

      recaptchaError: '',
      recaptchaErrorVisible: false,

      registerError: '',
      registerErrorVisible: false,
      loading: false,

      areaOfWorkItems: [
        'Developer',
        'Tester',
        'Educator',
        'Other',
      ],
    };
  },
  components: {
    VueRecaptcha,
  },
  methods: {
    async register() {
      if (!this.checkbox) {
        return;
      }

      this.recaptchaError = '';
      this.recaptchaErrorVisible = false;

      if (!this.isRecaptchaVerified) {
        this.recaptchaError = 'reCAPTCHA completion is mandatory!';
        this.recaptchaErrorVisible = true;
        return;
      }

      this.registerError = '';
      this.registerErrorVisible = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      try {
        await authService.register(
          this.fullName,
          this.email,
          this.password,
          this.company,
          this.areaOfWork?.toLowerCase(),
          this.linkedin,
        );
        this.$router.push({ path: '/login', query: { registerSuccess: true } });
      } catch (e) {
        this.registerError = e?.response?.data?.payload?.message || 'Failed! Registration error!';
        this.registerErrorVisible = true;
      }
      this.loading = false;
    },

    async onRecaptchaVerified(response) {
      const responseApi = await recaptchaService.verifyToken(response);
      this.isRecaptchaVerified = responseApi;
    },

    async onRecaptchaExpired() {
      this.isRecaptchaVerified = false;
    },
  },

  computed: {
    fullNameErrors() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.maxLength && errors.push(`Full name name must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.fullName.required && errors.push('Full name is required');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid email');
      !this.$v.email.maxLength && errors.push(`Email must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.email.required && errors.push('Email is required');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength && errors.push(`Password must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.maxLength && errors.push(`Password confirmation must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.passwordConfirm.required && errors.push('Password confirmation is required');
      !this.$v.passwordConfirm.sameAsPassword && errors.push('Password confirmation must be the same as the password');
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.company.$dirty) return errors;
      !this.$v.company.maxLength && errors.push(`Company must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.company.required && errors.push('Company is required');
      return errors;
    },
    areaOfWorkErrors() {
      const errors = [];
      if (!this.$v.areaOfWork.$dirty) return errors;
      !this.$v.areaOfWork.required && errors.push('Area of work is required');
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.required && errors.push('');
      return errors;
    },
    linkedinErrors() {
      const errors = [];
      if (!this.$v.linkedin.$dirty) return errors;
      !this.$v.linkedin.maxLength && errors.push(`LinkedIn URL must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      return errors;
    },
  },
};
</script>
