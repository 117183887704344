<template>
  <v-list nav dense>
    <div v-for="(item, index) in menu" :key="index"
      @click="$emit('click')" @keypress="$emit('keypress')"
    >
      <div v-if="item.text" class="pa-1 mt-2 overline">{{ item.text }}</div>
      <nav-menu :menu="item.items" />
    </div>
  </v-list>
</template>

<script>
import NavMenu from './NavMenu.vue';

export default {
  components: {
    NavMenu,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
