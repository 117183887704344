import { baseURL, api } from './api.service';

async function verifyToken(recaptchaToken: string): Promise<boolean | null> {
  const response = await api().post(`${baseURL}/recaptcha/verify`, { recaptcha: recaptchaToken });
  if (response.status === 200) {
    return response.data.payload === true;
  }

  return false;
}

export default {
  verifyToken,
};
