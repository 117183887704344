export enum ServerError {
    NO_ERROR = 200,
    UNAUTHORISED = 401,
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    TOO_MANY_REQUESTS = 429,
    CONNECTION_TIMEOUT = 440,
    INTERNAL_SERVER_ERROR = 500
}

export interface IServerErrorData {
    message: string
}
