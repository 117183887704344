// Time to wait (in ms) in between sending two emails
export const DEFAULT_MAIL_FREQ = '5000';
// The "from" field in the emails sent
export const DEFAULT_NO_REPLY_EMAIL_ADDRESS = 'OxidOS NO-REPLY <noreply@oxidos.io>';
// Maximum user input string allowed
export const DEFAULT_MAXIMUM_INPUT_STRING = 300;
// Maximum email confirmation expiration
export const DEFAULT_CONFIRM_EMAIL_TOKEN_EXPIRATION_MINUTES = '1440';
// Maximum reset password expiration
export const DEFAULT_RESET_TOKEN_EXPIRATION_MINUTES = '5';
// Default JWT expiration
export const DEFAULT_JWT_EXPIRATION = '1d';
// Maximum number of projects per user
export const DEFAULT_MAX_NUMBER_OF_PROJECTS_PER_USER='1';
// Valability of projects before being put to sleep if no hearbeat occurs
export const DEFAULT_DURATION_BEFORE_SLEEP_HOURS = '12';
// Hours interval to check for projects that need to be put to sleep
export const DEFAULT_SLEEP_CHECK_INTERVAL_HOURS='1';
// Valability of projects that are not used and are stale
export const DEFAULT_STALE_PROJECT_EXPIRATION_DAYS='14';
// Hours interval to check for stale projects
export const DEFAULT_STALE_PROJECT_CLEANUP_INTERVAL_HOURS='24';
// How many times to check for UNKNOWN for deleting stale projects
export const DEFAULT_STALE_PROJECT_CLEANUP_MAX_ITERATIONS_CHECK=96;
// How often to check for UNKNOWN for deleting stale projects (ms)
export const DEFAULT_STALE_PROJECT_CLEANUP_TIMEOUT_CHECK=5000;
