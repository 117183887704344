import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ProjectsView from '../views/ProjectsView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ChangePasswordView from '../views/ChangePasswordView.vue';
import ConfirmEmailView from '../views/ConfirmEmailView.vue';
import SettingsView from '../views/SettingsView.vue';
import StepsView from '../views/StepsView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Projects',
    component: ProjectsView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordView,
  },
  {
    path: '/reset-password',
    name: 'ChangePassword',
    component: ChangePasswordView,
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmailView,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
  },
  {
    path: '/steps',
    name: 'Steps',
    component: StepsView,
  },
  {
    path: '*',
    redirect: '/',
  },

];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password', '/confirm-email', '/steps'];
  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem('token');

  if (authRequired && !token && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password', '/confirm-email', '/steps'];
  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem('token');

  if (!authRequired && token && to.path !== '/') {
    next('/');
  } else {
    next();
  }
});

export default router;
