import axios from 'axios';
import { ServerError } from '@common/errors';

export const baseURL = process.env.VUE_APP_BASE_URL;
export const documentationURL = process.env.VUE_APP_DOCUMENTATION_BASE_URL;

if (!baseURL) {
  throw new Error('No VUE_APP_BASE_URL present!');
}

if (!documentationURL) {
  throw new Error('No DOCUMENTATION_URL present!');
}

export const api = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const axiosInstance = axios.create({
      baseURL,
      timeout: 20000,
      headers: { Authorization: `Bearer ${token}` },
    });
    axiosInstance.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === ServerError.UNAUTHORISED) {
        localStorage.removeItem('token');
        window.location.assign('/login');
      }
      return Promise.reject(error);
    });
    return axiosInstance;
  }
  return axios.create({
    baseURL,
    timeout: 20000,
  });
};
