<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer
      class="elevation-1"
      :right="$vuetify.rtl"
      width="260"
      permanent
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img src="/assets/logo.png" width="100%" alt="logo" class="mr-1">
        </div>
        <v-divider class="mb-1"></v-divider>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <main-menu :menu="logoutMenu" @click="logout" />
      </template>
    </v-navigation-drawer>

    <v-container :fluid="false" class="pt-3">
      <div class="py-2 fill-height" style="position:relative">
        <h1 class="text-h4">Settings</h1>
        <v-divider class="my-2"></v-divider>
        <v-card class="pa-4">

          <template v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="24"
            ></v-progress-circular>
          </template>
          <template v-else-if="userData?.email">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="userData.fullName"
                  label="Full name"
                  outlined
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="userData.email"
                  label="Email"
                  outlined
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="userData.company"
                  label="Company"
                  outlined
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="areaOfWorkMapper"
                  :items="[areaOfWorkMapper]"
                  label="Area of work"
                  outlined
                  disabled
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4"></v-divider>

            <form ref="form"
              @submit.prevent
              class="mt-2"
            >
              <v-row class="align-items-center">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="password"
                    :error-messages="passwordErrors"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    @blur="$v.password.$touch"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="passwordConfirm"
                    :error-messages="passwordConfirmErrors"
                    label="Password confirmation"
                    :type="showPasswordConfirm ? 'text' : 'password'"
                    :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPasswordConfirm = !showPasswordConfirm"
                    @blur="$v.passwordConfirm.$touch"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    type="submit" class="secondary match-password-input"
                    value="change" @click="changePassword"
                    :loading="loadingButton"
                    block large
                  >Change Password</v-btn>
                </v-col>
              </v-row>
            </form>
          </template>

        </v-card>
        <div
          class="
            d-flex flex-column flex-sm-row align-center justify-space-between
            overline position-absolute
          "
          style="bottom:0;left:0;right:0"
        >
          <div class="d-flex flex-column flex-sm-row align-center">
            <div class="secondary--text">© OxidOS 2024</div>
            <a href="assets/Privacy_Notice_OxidOS_M23.pdf"
              class="text-decoration-none ml-sm-2 secondary--text text--lighten-3"
            >Privacy Policy</a>
            <a href="assets/End_User_License_Agreement_OxidOS_Demonstrator_M23.pdf"
              class="text-decoration-none ml-sm-2 secondary--text text--lighten-3"
            >Terms of Service</a>
          </div>
          <div class="mt-2 mt-md-0">
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank" href="https://www.facebook.com/oxidos.automotive/">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"/></svg>
            </v-btn>
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank" href="https://twitter.com/OxidOSauto">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425
                5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86
                13.028h1.36L4.323 2.145H2.865z"/>
              </svg>
            </v-btn>
            <v-btn icon color="secondary lighten-2" class="ml-1" target="_blank" href="https://www.linkedin.com/company/oxidos-automotive/">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>

    <v-snackbar
      v-model="snackbarErrorVisible"
      :timeout="3000"
      color="red lighten-1"
    >
      {{ snackbarErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          variant="text"
          text
          v-bind="attrs"
          @click="snackbarErrorVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarVisible"
      :timeout="3000"
      color="secondary lighten-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          variant="text"
          text
          v-bind="attrs"
          @click="snackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-layout>

</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import authService from '@/services/auth.service';
import MainMenu from '@/components/navigation/MainMenu.vue';
import { DEFAULT_MAXIMUM_INPUT_STRING } from '@common/defaults';
import { documentationURL } from '@/services/api.service';

export default {
  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    passwordConfirm: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING), sameAsPassword: sameAs('password') },
  },
  components: {
    MainMenu,
  },
  data: () => ({
    userData: {},

    loading: false,
    loadingButton: false,

    snackbarVisible: false,
    snackbarText: '',

    snackbarErrorVisible: false,
    snackbarErrorText: '',

    projectStatus: {},
    projectTimeout: {},

    password: '',
    showPassword: false,
    passwordConfirm: '',
    showPasswordConfirm: false,

    menu: [{
      text: '',
      items: [
        {
          icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/',
        },
        {
          icon: 'mdi-school', key: 'menu.tutorial', text: 'Tutorials', link: `${documentationURL}/documentation/tutorial/index.html`, external: true,
        },
        {
          icon: 'mdi-tools', key: 'menu.workshop', text: 'Workshop', link: `${documentationURL}/documentation/workshop/index.html`, external: true,
        },
        {
          icon: 'mdi-book', key: 'menu.manual', text: 'Manual', link: `${documentationURL}/documentation/manual/index.html`, external: true,
        },
        {
          icon: 'mdi-book-cog', key: 'menu.api.documentation', text: 'API Documentation', link: `${documentationURL}/documentation/api/index.html`, external: true,
        },
        {
          icon: 'mdi-lifebuoy',
          key: 'menu.support',
          text: 'Support',
          link: 'https://github.com/orgs/OxidosAutomotive/discussions/categories/developer-platform-issues-and-feedback',
          external: true,
        },
        {
          icon: 'mdi-cog', key: 'menu.settings', text: 'Settings', link: '/settings',
        },
      ],
    }],
    logoutMenu: [{ text: '', items: [{ icon: 'mdi-logout', key: 'menu.logout', text: 'Logout' }] }],

    maxProjects: null,

  }),
  created() {
    this.initialize();
  },

  methods: {
    logout() {
      authService.logout();
      this.$router.push('/login');
    },
    async initialize() {
      this.loading = true;
      try {
        this.userData = (await authService.getUser()).data?.payload;
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed to fetch user data!';
        this.snackbarErrorVisible = true;
      }
      this.loading = false;
    },

    async changePassword() {
      this.snackbarErrorVisible = false;
      this.snackbarVisible = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loadingButton = true;
      try {
        await authService.changePassword(this.password);
        this.snackbarText = 'Password was changed successfully!';
        this.snackbarVisible = true;
        this.$v.$reset();
        this.password = '';
        this.passwordConfirm = '';
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed! Password change was not successful!';
        this.snackbarErrorVisible = true;
      }
      this.loadingButton = false;
    },

  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength && errors.push(`Password must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.maxLength && errors.push(`Password confirmation must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.passwordConfirm.required && errors.push('Password confirmation is required');
      !this.$v.passwordConfirm.sameAsPassword && errors.push('Password confirmation must be the same as the password');
      return errors;
    },
    areaOfWorkMapper() {
      if (this.userData?.areaOfWork) {
        const text = this.userData.areaOfWork;
        if (text === 'developer') {
          return 'Developer';
        } if (text === 'tester') {
          return 'Tester';
        } if (text === 'educator') {
          return 'Educator';
        } if (text === 'other') {
          return 'Other';
        }
        return text;
      }
      return '';
    },
  },
};
</script>
<style scoped>
.match-password-input {
  min-height: 56px !important;
}
</style>
