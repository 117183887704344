import { AxiosResponse } from 'axios';
import { IUser } from '@common/users';
import { api } from './api.service';

/**
 * It is important to know that this functions sets two pieces of information once a
 * user logs in:
 * 1) a token stored in local storage used for authenticating api calls to the backend
 * 2) a cookie with an expiration time of 1 day based on the previous token that is
 *    used for accessing the documentation
 * This is the only place a cookie is set.
 */
async function login(email: string, password: string) {
  const response = await api().post('/auth/signin', { email, password });
  const token = response.data?.payload?.token;

  if (token) {
    localStorage.setItem('token', token);

    const date = new Date();
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));

    document.cookie = `docCookie=${token}; expires=${date}; domain=.oxidos.io; path=/`;
  } else {
    throw new Error('No token available');
  }
}

async function register(
  fullName: string,
  email: string,
  password: string,
  company: string,
  areaOfWork: string,
  linkedin: string|null,
) {
  await api().post('/auth/signup', {
    fullName,
    email,
    password,
    company,
    areaOfWork,
    linkedin,
  });
}
/**
 * Unless the cookie expires on its own, if the user logs out, the cookie is set
 * to undefined to prevent any subsequent unauthorized accesses. The user must log
 * in once again to regenerate its access cookie.
 */
function logout() {
  const date = new Date();

  // Set it to expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  localStorage.removeItem('token');
  document.cookie = `docCookie=${undefined}; expires=${date}; domain=.oxidos.io; path=/`;
}

async function forgotPassword(email: string) {
  const response = await api().post('/auth/forgot-password', { email });
  return response;
}

async function resetPasswordTokenExists(token: string) {
  const response = await api().post('/auth/reset-password-token-exists', { token });
  return response;
}

async function resetPassword(token: string, password: string) {
  const response = await api().post('/auth/reset-password', { token, password });
  return response;
}

async function confirmEmail(token: string) {
  const response = await api().post('/auth/confirm-email', { token });
  return response;
}

async function getUser(): Promise<AxiosResponse<IUser>> {
  const response = await api().get<IUser>('/auth/user');
  return response;
}

async function changePassword(password: string) {
  const response = await api().post('/auth/change-password', { password });
  return response;
}

export default {
  login,
  register,
  logout,
  forgotPassword,
  resetPasswordTokenExists,
  resetPassword,
  confirmEmail,
  getUser,
  changePassword,
};
