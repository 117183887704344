export enum ClusterGeneralStatus {
    CREATED = "created",
    UNKNOWN = "unknown"
}

export enum ResultType {
    SUCCESS= "success",
    ERROR= "error"
}

export interface ISuccess {
    type: ResultType.SUCCESS,
    description: string,
}

export interface IError {
    type: Exclude <ResultType, ResultType.SUCCESS>,
    description: string,
}

export interface IStatusSuccess extends ISuccess {
    status: ClusterGeneralStatus
}

export interface IStatusError extends IError {
    status: ClusterGeneralStatus.UNKNOWN
}

type Success = ISuccess | IStatusSuccess;
type Error = IError | IStatusError
export class Result<T extends Success | Error> {
    constructor (public readonly value: T) {}

    isSuccess(): boolean {
        return this.value.type == "success";
    }

    isError(): boolean {
        return this.value.type == "error";
    }
}

export interface IOxidOSClusterStatus {
	namespace: Result<IStatusSuccess | IStatusError>;
	pvc: Result<IStatusSuccess | IStatusError>;
	cmap: Result<IStatusSuccess | IStatusError>;
	deployment: Result<IStatusSuccess | IStatusError>;
	pod: Result<IStatusSuccess | IStatusError>;
	service: Result<IStatusSuccess | IStatusError>;
	ingress: Result<IStatusSuccess | IStatusError>;
}

export function getClusterURL (projectId: string, vscodeToken: string): {actual: string, visible: string} {
    return {
        actual: `https://${projectId}.cluster.oxidos.io/?tkn=${vscodeToken}&workspace=/home/oxidos/OxidOS-Simulator/OxidOS-Simulator.code-workspace`,
        visible: `${projectId}.cluster.oxidos.io`
    }
}
