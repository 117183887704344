<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Change Password</h2>
    </v-responsive>

    <v-progress-circular
      v-if="loadingToken"
      indeterminate
      color="secondary"
      class="pa-4 mx-auto d-block"
    ></v-progress-circular>

    <v-card v-if="!snackbarTokenVisible && !loadingToken" class="pa-4 mx-auto" max-width="600">
      <v-card-text >
        <form ref="form" @submit.prevent>
          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @blur="$v.password.$touch"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            :error-messages="passwordConfirmErrors"
            label="Password confirmation"
            :type="showPasswordConfirm ? 'text' : 'password'"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
            @blur="$v.passwordConfirm.$touch"
            outlined
          ></v-text-field>
          <v-btn
            type="submit" class="secondary"
            value="change" @click="changePassword"
            :loading="loading"
            block x-large
          >Change Password</v-btn>
        </form>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbarErrorVisible"
      :timeout="3000"
      color="red lighten-1"
    >
      {{ snackbarErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          variant="text"
          text
          v-bind="attrs"
          @click="snackbarErrorVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarTokenVisible"
      :timeout="-1"
      color="red lighten-1"
    >
      {{ snackbarTokenText }}
    </v-snackbar>

  </v-container>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import authService from '@/services/auth.service';
import { DEFAULT_MAXIMUM_INPUT_STRING } from '@common/defaults';

export default {
  name: 'ChangePasswordView',

  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
    passwordConfirm: { required, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING), sameAsPassword: sameAs('password') },
  },

  data() {
    return {
      password: '',
      showPassword: false,
      passwordConfirm: '',
      showPasswordConfirm: false,
      loading: false,
      token: this?.$route?.query?.token,

      loadingToken: true,

      snackbarTokenText: '',
      snackbarTokenVisible: false,

      snackbarErrorText: '',
      snackbarErrorVisible: false,
    };
  },

  async mounted() {
    this.loadingToken = true;
    if (this.token) {
      try {
        await authService.resetPasswordTokenExists(this.token);
      } catch (e) {
        this.snackbarTokenText = 'Reset password session is not valid. Please try sending again the reset password email.';
        this.snackbarTokenVisible = true;
      }
    } else {
      this.snackbarTokenText = 'Reset password session is not valid. Please try sending again the reset password email.';
      this.snackbarTokenVisible = true;
    }
    this.loadingToken = false;
  },

  methods: {
    async changePassword() {
      this.snackbarErrorVisible = false;
      this.snackbarTokenVisible = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      try {
        await authService.resetPassword(this.token, this.password);
        this.$router.push({ path: '/login', query: { passwordChanged: true } });
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed! Password change was not successful!';
        this.snackbarErrorVisible = true;
      }
      this.loading = false;
    },
  },

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength && errors.push(`Password must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.maxLength && errors.push(`Password confirmation must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.passwordConfirm.required && errors.push('Password confirmation is required');
      !this.$v.passwordConfirm.sameAsPassword && errors.push('Password confirmation must be the same as the password');
      return errors;
    },
  },
};
</script>
