import icons from './icons';
import theme from './theme';
import analytics from './analytics';

export default {
  // product display information
  product: {
    name: 'OxidOS',
    version: 'v1.0.0',
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,
};
