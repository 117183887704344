<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Forgot Password</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <form ref="form" @submit.prevent>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="Email"
            @blur="$v.email.$touch"
            outlined
          ></v-text-field>
          <v-btn
            type="submit" class="secondary"
            value="forgot" @click="forgotPassword"
            :loading="loading"
            block x-large
          >Send Change Password Request</v-btn>
        </form>

      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbarVisible"
      :timeout="-1"
      color="secondary lighten-1"
    >
      {{ snackbarText }}
    </v-snackbar>

    <v-snackbar
      v-model="snackbarErrorVisible"
      :timeout="3000"
      color="red lighten-1"
    >
      {{ snackbarErrorText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          variant="text"
          text
          v-bind="attrs"
          @click="snackbarErrorVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import authService from '@/services/auth.service';
import { DEFAULT_MAXIMUM_INPUT_STRING } from '@common/defaults';

export default {
  name: 'ForgotPasswordView',

  mixins: [validationMixin],
  validations: {
    email: { required, email, maxLength: maxLength(DEFAULT_MAXIMUM_INPUT_STRING) },
  },

  data() {
    return {
      email: '',
      loading: false,

      snackbarText: '',
      snackbarVisible: false,

      snackbarErrorText: '',
      snackbarErrorVisible: false,
    };
  },
  methods: {
    async forgotPassword() {
      this.snackbarErrorVisible = false;
      this.snackbarVisible = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      try {
        await authService.forgotPassword(this.email);
        this.snackbarText = 'An email with a link for changing the password email has been sent successfully!';
        this.snackbarVisible = true;
      } catch (e) {
        this.snackbarErrorText = e?.response?.data?.payload?.message || 'Failed! Cannot send the changing password email!';
        this.snackbarErrorVisible = true;
      }
      this.loading = false;
    },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid email');
      !this.$v.email.maxLength && errors.push(`Email must be at most ${DEFAULT_MAXIMUM_INPUT_STRING} characters long`);
      !this.$v.email.required && errors.push('Email is required');
      return errors;
    },
  },
};
</script>
